<div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field>
        <input matInput [matDatepicker]="fromDatePicker" [placeholder]="fromDatePlaceHolder"
            [formControl]="fromDateField" (focus)="fromDatePicker.open()">
        <mat-error *ngIf="toDateField.hasError('required')">
            {{fromDatePlaceHolder}} را انتخاب کنید.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <input matInput [matDatepicker]="toDatePicker" [placeholder]="toDatePlaceHolder" [min]="fromDate"
            [formControl]="toDateField" (focus)="toDatePicker.open()">
        <mat-error *ngIf="toDateField.hasError('required')">
            {{toDatePlaceHolder}} را انتخاب کنید.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker [startAt]="fromDate"></mat-datepicker>
    </mat-form-field>
</div>