import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MaterialPersianDateAdapter,
  PERSIAN_DATE_FORMATS,
} from "../../Adapter/material-persian-date-adapter";

@Component({
  selector: "app-persian-date-picker",
  templateUrl: "./persian-date-picker.component.html",
  styleUrls: ["./persian-date-picker.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MaterialPersianDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: PERSIAN_DATE_FORMATS },
  ],
})
export class PersianDatePickerComponent implements OnInit {
  @Input() placeHolder: string;
  @Input() date: string;
  @Output() dateChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  input(event: Event) {
  }
}
