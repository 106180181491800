import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[appHasAuthUserViewPermission]'
})
export class HasAuthUserViewPermissionDirective implements OnInit, OnDestroy {

  private isVisible = false;
  private requiredRoles: string[] | null = null;
  private authSubscription: Subscription;

  @Input()
  set appHasAuthUserViewPermission(roles: string[] | null) {
    this.requiredRoles = roles;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authSubscription = this.authService.authStatus$.subscribe(status => {
    this.changeVisibility(status);
    });
    this.changeVisibility(true);

  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  private changeVisibility(status: boolean) {
    const isInRoles = !this.requiredRoles ? true : this.authService.isAuthUserInRoles(this.requiredRoles);
    if (isInRoles && status) {
      if (!this.isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isVisible = true;
      }
    } else {
      this.isVisible = false;
      this.viewContainer.clear();
    }
  }

}
