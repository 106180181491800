import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'jalali-moment';

@Pipe({
  name: 'jalali'
})
export class JalaliPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args) {
      return moment(new Date(value)).format(args);
    } else {
      const MomentDate = moment(new Date(value));
      return MomentDate.locale('fa').format('YYYY/MM/DD');
    }

  }

}
