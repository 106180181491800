import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { FlexLayoutModule } from "@angular/flex-layout";
//import { TextMaskModule } from "angular2-text-mask";
import { MaterialModule } from "./material.module";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { JalaliPipe } from "./pipes/jalali.pipe";
import { LoadingScreenComponent } from "./components/loading-screen/loading-screen.component";
import { HasAuthUserViewPermissionDirective } from "./directives/has-auth-user-view-permission.directive";
import { QuillModule } from "ngx-quill";
import { PersianDatePickerComponent } from './components/persian-date-picker/persian-date-picker.component';
import { RangePersianDatePickerComponent } from './components/range-persian-date-picker/range-persian-date-picker.component';

import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    JalaliPipe,
    LoadingScreenComponent,
    HasAuthUserViewPermissionDirective,
    PersianDatePickerComponent,
    RangePersianDatePickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    //TextMaskModule,
    //MatSelectFilterModule,
    NgxMatSelectSearchModule,
    //MatSelectFilterModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["clean"],
        ]
      }
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    JalaliPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxMatSelectSearchModule,
    //  TextMaskModule,
    LoadingScreenComponent,
    HasAuthUserViewPermissionDirective,
    QuillModule,
    PersianDatePickerComponent,
    RangePersianDatePickerComponent
  ],
  providers: [
    provideNgxMask(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ]
})
export class SharedModule { }
