import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "jalali-moment";
import {
  MaterialPersianDateAdapter,
  PERSIAN_DATE_FORMATS,
} from "../../Adapter/material-persian-date-adapter";

@Component({
  selector: "app-range-persian-date-picker",
  templateUrl: "./range-persian-date-picker.component.html",
  styleUrls: ["./range-persian-date-picker.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MaterialPersianDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: PERSIAN_DATE_FORMATS },
  ],
})
export class RangePersianDatePickerComponent implements OnInit {
  @Input() fromDatePlaceHolder: string;
  @Input() toDatePlaceHolder: string;
  @Input() required = false;
  @Input() fromDate: string;
  @Output() fromDateChange = new EventEmitter<string>();
  @Input() toDate: string;
  @Output() toDateChange = new EventEmitter<string>();
  fromDateField: UntypedFormControl;
  toDateField: UntypedFormControl;

  constructor() {}

  ngOnInit(): void {
    this.fromDateField = new UntypedFormControl(this.fromDate);
    this.toDateField = new UntypedFormControl(this.toDate);
    if (this.required) {
      this.fromDateField.setValidators(Validators.required);
      this.toDateField.setValidators(Validators.required);
    }
    this.initChangeValue();
    if (this.fromDate) {
      this.fromDateField.setValue(
        moment(new Date(this.fromDate)).locale("fa").format("YYYY/MM/DD")
      );
    }
    if (this.toDate) {
      this.toDateField.setValue(
        moment(new Date(this.toDate)).locale("fa").format("YYYY/MM/DD")
      );
    }
  }

  initChangeValue() {
    this.fromDateField.valueChanges.subscribe((res) => {
      this.fromDateChange.emit(moment.from(res, "en").utc(true).toJSON());
    });
    this.toDateField.valueChanges.subscribe((res) => {
      this.toDateChange.emit(moment.from(res, "en").utc(true).toJSON());
    });
  }
}
